var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tabs__content tabs__content_with_doc-getter"},[_vm._l((_vm.docGetterCardTitles),function(title,i){return _c('doc-getter-card',{key:i,attrs:{"title":title},on:{"download":() => {
        _vm.currentReportIndex = i;
        _vm.showDocDownloadingDialog = true;
      },"send":() => {
        _vm.currentReportIndex = i;
        _vm.showDocSendingDialog = true;
      }}})}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocSendingDialog,"is-send-mode":true,"report":_vm.selectedReport},on:{"agree":({ payload: email }) => {
        _vm.closeAllDialogs();
        _vm.targetEmail = email;
        _vm.useDocSendingDialogNotice = !_vm.useDocSendingDialogNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocDownloadingDialog,"report":_vm.selectedReport},on:{"agree":_vm.closeAllDialogs,"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useDocSendingDialogNotice}},[_vm._v(" Документы успешно отправлены на email: "+_vm._s(_vm.targetEmail)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }