var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('XDataTable',{staticClass:"data-table_type_consumption",attrs:{"caption":`Точка учета: ${_vm.pointsByObject.tuName}`,"headers":_vm.headers,"items":_vm.preparedItems,"hideDefaultFooter":"","hideDefaultHeader":"","useItemSlot":true},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('a',{ref:"anchor"})]},proxy:true},{key:"item",fn:function({ item: { index, item } }){return [(index>0)?_c('tr',{staticClass:"data-table__text",on:{"click":function($event){return _vm.onTableRow(index)}}},_vm._l((item),function(tdValue,header){return _c('td',{key:header},[(header.endsWith('action'))?[_c('div',{class:[
              'd-flex',
              'align-center',
              'justify-end',
              'fill-height',
              ' fluid',
            ]})]:[_c('span',{class:{ 'text-h6': !index }},[_vm._v(" "+_vm._s(tdValue || "—")+" ")])]],2)}),0):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }