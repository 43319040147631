var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-card',{staticClass:"card_type_consumption-objects"},[_c('ul',{staticClass:"card__rows pl-0"},[_vm._l((_vm.pointsByObjects),function(pointsByObject,i){return [_c('v-row',{key:`a-${i}`,staticClass:"card__row align-center",attrs:{"tag":"li"},on:{"click":() => {
            const { objectId, objectName } = pointsByObject;

            _vm.$emit('update:sliderPreloader', true);
            _vm.$emit('update:slider', true);
            _vm.$emit('update:tableCaption', objectName || 'Без привязки');
            _vm.$emit('update:sliceId', objectId);
          }}},[_vm._v(" "+_vm._s(pointsByObject.objectName || "Без привязки")+" ")]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(i < _vm.pointsByObjects.length),expression:"i < pointsByObjects.length"}],key:`b-${i}`})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }