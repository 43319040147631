var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-data-table',_vm._b({staticClass:"data-table_as_tile",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedPoints,"item-class":"text-body-1","caption":_vm.points.objectName || 'Без привязки'},on:{"click:row":({ number }, { index }) => {
      _vm.$emit('update:sliderPreloader', true);
      _vm.$emit('update:slider', true);
      _vm.$emit('update:tableCaption', `Точка учета № ${number}, ${_vm.points.pointList[index].адрес}`);
      _vm.$emit(
        'update:sliceId',
        _vm.points.pointList.find((_, i) => i === index)['лицевой']
      );
    }}},'x-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }