
import { Component, Prop, Vue } from "vue-property-decorator";
import { prepareMoney } from "@/assets/scripts/utils";
import { formatDate } from "@/lib/date";
import { OrganizationTurnoversDetails } from "@/models/organization";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
})
class PaymentTable extends Vue {
  @Prop({ default: () => [] }) readonly items!: OrganizationTurnoversDetails[];

  headers = [
    { text: "Тип документа", value: "docType" },
    { text: "Дата", value: "docDate" },
    { text: "Номер", value: "docNumber" },
    { text: "Сумма с НДС", value: "amountWithTax", align: "end" },
    { text: "Вид деятельности", value: "activityType" },
  ];

  public get preparedItems() {
    const requiredKeys = ["тип", "дата", "номер", "сумма", "аналитика"];

    return this.items.map((item) =>
      requiredKeys.map((key, i) => {
        const value = item[key];

        if (i === 1) return formatDate(value, "full");

        if (i === 3) return prepareMoney(value);

        return value;
      })
    );
  }
}

export default PaymentTable;
