
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDate } from "@/lib/date";
import { OrganizationTurnovers } from "@/models/organization";
import XChart from "../hoc/Chart.vue";

@Component({
  components: { XChart },
})
class PaymentChart extends Vue {
  @Prop({ required: true }) readonly turnoversList!: OrganizationTurnovers[];

  fontFamily = "Roboto";
  fontSize = 14;

  // @ts-ignore
  labelColor = this.$vuetify.theme.currentTheme.chart.darken1;
  // @ts-ignore
  fontColor = this.$vuetify.theme.currentTheme.chart.lighten2;
  // @ts-ignore
  gridLines = this.$vuetify.theme.currentTheme.chart.lighten3;
  // @ts-ignore
  backgroundColor = this.$vuetify.theme.currentTheme.chart.lighten1;
  // @ts-ignore
  borderColor = this.$vuetify.theme.currentTheme.chart.lighten3;
  // @ts-ignore
  bodyFontColor = this.$vuetify.theme.currentTheme.chart.darken2;
  // @ts-ignore
  accruedDataColor = this.$vuetify.theme.currentTheme.chart.darken3;
  // @ts-ignore
  paidDataColor = this.$vuetify.theme.currentTheme.chart.darken4;

  // Легенда
  legendProps = {
    labels: {
      fontColor: this.labelColor,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
    },
  };

  // Ось X и ось Y
  axesCommonProps = {
    scaleLabel: {
      display: true,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: this.labelColor,
    },
    ticks: {
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: this.fontColor,
    },
  };

  scalesProps = {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Период",
        },
      },
    ],
    yAxes: [
      {
        gridLines: { borderDash: [12], color: this.gridLines },
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Сумма",
        },
      },
    ],
  };

  // Всплывающая подсказка
  tooltipGaps = { rowGap: 8, padding: 16 };

  tooltipProps = {
    // Содержание и позиционирование
    mode: "index",
    position: "nearest",
    // Внешний вид контейнера
    backgroundColor: this.backgroundColor,
    borderColor: this.borderColor,
    borderWidth: 1,
    cornerRadius: 8,
    // Заголовок
    titleFontColor: this.labelColor,
    titleFontFamily: this.fontFamily,
    titleFontSize: this.fontSize,
    titleMarginBottom: this.tooltipGaps.rowGap,
    // Тело
    bodyFontColor: this.bodyFontColor,
    bodyFontFamily: this.fontFamily,
    bodySpacing: this.tooltipGaps.rowGap,
    // Указатель
    caretPadding: 8,
    caretSize: 16,
    // Отступы
    xPadding: this.tooltipGaps.padding,
    yPadding: this.tooltipGaps.padding,
  };

  cssRules = { position: "relative", height: "500px", width: "100%" };

  public get preparedTurnovers() {
    type PreparedTurnovers = {
      datesInterval: string[];
      paymentData: Array<number[]>;
    };

    let preparedTurnovers: PreparedTurnovers = {
      datesInterval: [],
      paymentData: [[], []],
    };

    return this.turnoversList.reduce(
      (acc: PreparedTurnovers, turnoversByMonth: OrganizationTurnovers) => {
        acc.datesInterval.push(
          formatDate(turnoversByMonth["месяц"], "monthAndYear")
        );

        acc.paymentData[0].push(turnoversByMonth["начислено"]);
        acc.paymentData[1].push(turnoversByMonth["оплата"]);

        return acc;
      },
      preparedTurnovers
    );
  }

  public get chartData() {
    const accruedData = {
      label: "Начислено",
      color: this.accruedDataColor,
    };
    const paidData = {
      label: "Оплачено",
      color: this.paidDataColor,
    };

    const { datesInterval, paymentData } = this.preparedTurnovers;

    const datasets = [accruedData, paidData].map(({ color, ...others }, i) => {
      return {
        ...others,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 2,
        fill: false,
        pointStyle: "circle",
        pointRadius: 4,
        data: paymentData[i],
      };
    });

    return { labels: datesInterval, datasets };
  }

  public get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: this.legendProps,
      scales: this.scalesProps,
      tooltips: this.tooltipProps,
    };
  }
}

export default PaymentChart;
