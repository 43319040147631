
import { OrganizationTurnoversDetails } from "@/models/organization";
import { prepareMoney } from "@/assets/scripts/utils";
import { formatDate } from "@/lib/date";
import { Component, Prop, Vue } from "vue-property-decorator";
import XDataTable from "../hoc/Table.vue";

@Component({
  components: { XDataTable },
})
class PrepaymentTable extends Vue {
  @Prop({ default: () => [] }) readonly items!: OrganizationTurnoversDetails[];
  headers = [
    { text: "Тип документа", value: "docType" },
    { text: "Дата", value: "docDate" },
    { text: "Номер", value: "docNumber" },
    { text: "Сумма с НДС", value: "amountWithTax", align: "end" },
    { text: "Сумма без НДС", value: "amountWithoutTax", align: "end" },
  ];

  public get preparedItems() {
    const requiredKeys = ["тип", "дата", "номер", "суммасндс", "суммабезндс"];

    return this.items.map((item) =>
      requiredKeys.map((key, i) => {
        const value = item[key];

        if (i === 1) return formatDate(value, "full");

        if ([3, 4].includes(i)) return prepareMoney(value);

        return value;
      })
    );
  }
}

export default PrepaymentTable;
