var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-data-table',{staticClass:"data-table_with_result-row",attrs:{"caption":"Обороты","headers":_vm.preparedHeaders,"items":_vm.preparedTurnoversByMonths,"item-class":_vm.useItemClass && 'data-table__text_type_td',"search":_vm.search,"paginationProps":_vm.paginationProps},on:{"update:paginationProps":function($event){_vm.paginationProps=$event},"update:pagination-props":function($event){_vm.paginationProps=$event},"click:row":(rowData) => {
      const { month } = rowData;

      if (month !== 'Итого') {
        _vm.$emit('update:month', _vm.getDateByString(month));
      }
    }},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('v-text-field',{staticClass:"data-table__search",attrs:{"placeholder":"Поиск","prepend-inner-icon":"mdi-magnify","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }